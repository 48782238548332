.list {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 214px;
	height: 54px;
	border-radius: 15px;
	cursor: pointer;
	text-decoration: none;
	color: white;
}

.list:active,
.list:visited,
.list:focus,
.list:active {
	color: white;
}

.list:hover {
	background: #f2c94c;
	color: #222b32;
	text-decoration: none;
}
